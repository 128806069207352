<template>
  <div>
    <div
      v-if="
        typeof error_messages === 'object' &&
        Object.keys(error_messages).length > 0
      "
      class="alert alert-danger"
    >
      <ul>
        <template v-for="(error, i) in error_messages">
          <span v-bind:key="i">
            <li v-for="(message, ii) in error" v-bind:key="ii">
              {{ message }}
            </li>
          </span>
        </template>
      </ul>
    </div>
    <div class="tabs">
      <v-tabs
        v-model="tab"
        background-color="light primary"
        class="elevation-2"
        dark
      >
        <v-tab>{{ $t("COMPONENTS.HALLS") }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <vue-form-generator
                ref="form"
                :schema="hallSchema"
                :model="hall"
                :options="formOptions"
              ></vue-form-generator>

              <v-row class="mb-5">
                <v-col class="p-0">
                  <v-col class="row detail-labels p-0">
                    <v-col class="p-0">
                      <v-checkbox
                        v-model="hall.qualityControlMail"
                        hide-details
                      ></v-checkbox>
                      Изпрати мейл до контрол на качеството при чекиране на
                      оператор в паузирана МК.
                    </v-col>
                  </v-col>
                </v-col>
              </v-row>

              <v-btn
                @click="save()"
                class="btn btn-success btn-elevate kt-login__btn-primary text-white"
                >{{ $t("BUTTON.SAVE") }}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

import VueFormGenerator from "vue-form-generator";
import "vue-form-generator/dist/vfg.css"; // optional full css additions

import { GET_HALL, SAVE_HALL, UPDATE_HALL } from "@/store/halls.module";

import { mapGetters, mapState } from "vuex";
// Vue.use(VueFormGenerator)
import _ from "lodash";

export default {
  name: "createUpdate",

  components: {
    "vue-form-generator": VueFormGenerator.component,
  },
  computed: {
    ...mapGetters(["isLoadingHalls"]),
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    isEdit: function () {
      if (typeof this.$route.params.id !== "undefined") {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      error_messages: [],
      model: "halls",
      tab: 0,
      isFormValid: false,
      hall: {
        name: null,
        email: null,
        password: null,
        qualityControlMail: false,
      },
      hallSchema: {
        fields: [
          {
            type: "input",
            inputType: "text",
            label: "Име",
            model: "name",
            required: true,
            validator: VueFormGenerator.validators.string.locale({
              fieldIsRequired: "Полето е задължително",
              textTooSmall: "Полето трябва да бъде минимум {1} знака !!!",
            }),
          },
          {
            type: "input",
            inputType: "text",
            label: "Email",
            model: "email",
            required: true,
            validator: VueFormGenerator.validators.string.locale({
              fieldIsRequired: "Полето е задължително",
              textTooSmall: "Полето трябва да бъде минимум {1} знака !!!",
            }),
          },
        ],
      },
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: false,
        validateAsync: true,
      },
    };
  },
  mounted() {
    let vm = this;

    _.delay(function () {
      var current_page = "Добави";
      if (typeof vm.$route.params.id !== "undefined") {
        current_page = "Промени";
      }
      vm.$store.dispatch(SET_BREADCRUMB, [
        { title: "Зали", route: "/halls" },
        { title: current_page },
      ]);
    }, 300);

    let passwordField = {
      type: "input",
      inputType: "password",
      label: "Парола",
      model: "password",
      min: 8,
      required: !vm.isEdit,
      validator: VueFormGenerator.validators.string.locale({
        fieldIsRequired: "Полето е задължително !!!",
        textTooSmall: "Полето трябва да бъде минимум {1} знака !!!",
      }),
    };

    let confirmPasswordField = {
      type: "input",
      inputType: "password",
      label: "Потвърди паролата",
      model: "passwordConfirmation",
      min: 8,
      required: !vm.isEdit,
      validator: VueFormGenerator.validators.string.locale({
        fieldIsRequired: "Полето е задължително",
        textTooSmall: "Полето трябва да бъде минимум {1} знака !!!",
      }),
    };

    vm.hallSchema.fields.push(passwordField);
    vm.hallSchema.fields.push(confirmPasswordField);

    if (typeof this.$route.params.id !== "undefined") {
      vm.getData();
    }
  },
  methods: {
    async save() {
      let vm = this;

      vm.error_messages = [];

      let errors = await this.$refs.form.validate();

      if (errors.length) {
        return;
      }

      let action =
        typeof this.$route.params.id !== "undefined" ? UPDATE_HALL : SAVE_HALL;

      vm.$store
        .dispatch(action, { id: this.$route.params.id, payload: vm.hall })
        .then((data) => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message,
          });

          vm.$router.push({ name: "hallsList" });
        })
        .catch((response) => {
          vm.error_messages = response.data.errors;
        });
    },
    getData: function () {
      let vm = this;
      this.error_messages = [];
      let id = this.$route.params.id;
      this.$store
        .dispatch(GET_HALL, id)
        .then((data) => {
          vm.$nextTick(function () {
            vm.hall = data.data;
          });
        })
        .catch((response) => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300,
          });

          if (typeof response === "undefined" || response.status === 404) {
            vm.$notification["error"]({
              message: "Грешка 404",
              description: "Страницата не е намерена!",
            });
            vm.$router.push({ name: "hallsList" });
          }
        });
    },
  },
};
</script>
